import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../../components/common/customMedia'
import { Reveal, Animation } from 'react-genie'

import { Layout } from '../../components/Layout'
import { SEO } from '../../components/common/SEO'
import { Image } from '../../components/common/Image'
import { SpBr } from '../../components/common/SpBr'
import { PcBr } from '../../components/common/PcBr'
import { SpOnly } from '../../components/common/SpOnly'
import { PcOnly } from '../../components/common/PcOnly'
import { Breadcrumb } from '../../components/common/Breadcrumb'
import { Jumbotron } from '../../components/common/Jumbotron'
import { Mission } from '../../components/common/Mission'
import { Message } from '../../components/common/Message'

const AboutMain = require('../../images/main4_pc.jpg')
const Video = require('../../videos/shinbi-do.mp4')
const AboutCheckSp = require('../../images/about_check_sp.jpg')
const AboutCheckPc = require('../../images/about_check_pc.jpg')

// import '../../components/About/About.css'

const IndexPage = () => (
  <Layout>
    <SEO
      title="軽井沢エリアで暮らしの信頼を提供する薬局 | シンビ堂 "
      description="わたしたちシンビ堂は暮らしの信頼を提供する薬局をモットーに安心を提供できるコミュニケーションを一番大切にしています。お薬のご相談はもちろん地域情報や道案内などお気軽にご相談ください。"
      keywords={['シンビ堂', '薬局', '軽井沢', '調剤薬局', 'モットー']}
    />
    <h1>わたしたち 軽井沢 薬局　調剤薬局 モットー | シンビ堂</h1>

    <Container>
      <Jumbotron
        imageSrc={AboutMain}
        enTitle="ABOUT US"
        jaTitle="わたしたち"
        objectPosition={35}
      />
      <BreadcrumbWrapper>
        <Breadcrumb color="blue" current="わたしたち" />
      </BreadcrumbWrapper>

      <Reveal delay={1000} animation={Animation.FadeIn}>
        <VideoWrapper>
          <video controls>
            <source src={Video} type="video/mp4" />
          </video>
        </VideoWrapper>
      </Reveal>

      <Reveal delay={700} animation={Animation.FadeIn}>
        <AboutMessage>暮らしの信頼を提供する薬局</AboutMessage>
      </Reveal>

      <Reveal delay={1000} animation={Animation.FadeIn}>
        <AboutSubMessage>
          軽井沢・中軽井沢・御代田エリアにお住まいの皆様を中心に、
          <PcBr />
          暮らしの安心を提供することを私たちのモットーにしております。 <PcBr />
          お薬のことだけでなく、地域の情報案内などを通じて、
          <PcBr />
          お客様との信頼関係を築くことを一番大切にしております。 <PcBr />
          シンビ堂はマニュアルだけでないお客様とのコミュニケーションを
          <PcBr />
          大切にする地域密着薬局です。
        </AboutSubMessage>
      </Reveal>

      <Mission showButton={true} />

      <Reveal delay={1000} animation={Animation.FadeIn}>
        <LogoWrapper>
          <Image path="about_zo@2x.png" />
        </LogoWrapper>
        <LogoText>LOGO MARK</LogoText>
      </Reveal>

      <Reveal delay={1200} animation={Animation.FadeIn}>
        <LogoSpDescription>「頼りになる薬局へ」象のマーク</LogoSpDescription>
        <LogoDescription>
          平和と力強さ、頼りになる象徴である「象」と
          <PcBr />
          シンビ堂の頭文字「S」を掛け合わせて
          <PcBr />
          作られたロゴマークです。
        </LogoDescription>
      </Reveal>

      <CheckContainer>
        <CheckImageWrapper>
          <SpOnly>
            <img src={AboutCheckSp} alt={AboutCheckSp} />
          </SpOnly>
          <PcOnly>
            <img src={AboutCheckPc} alt={AboutCheckPc} />
          </PcOnly>
        </CheckImageWrapper>
        <PlaidDescriptionWrapper>
          <Reveal delay={1400} animation={Animation.FadeInUp}>
            <PlaidText>PLAID</PlaidText>
          </Reveal>
          <Reveal delay={1600} animation={Animation.FadeInUp}>
            <PlaidSpDescription>
              「信頼と絆」のシンビ堂チェック
            </PlaidSpDescription>
          </Reveal>
          <Reveal delay={1800} animation={Animation.FadeInUp}>
            <PlaidDescription>
              「信頼と絆」をコンセプトとした、チェック柄です。
              <PcBr />
              薬局の象徴である十字マークが際立つ
              <PcBr />
              デザインになっています。
            </PlaidDescription>
          </Reveal>
        </PlaidDescriptionWrapper>
      </CheckContainer>
      <MessageWrapper>
        <Message showSubmessage={true} />
      </MessageWrapper>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin: 15px 0 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    top: 590px;
    left: 100px;
    position: absolute;
  `}
`

const VideoWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 23px;

    video {
      width: 100%;
    }
  `}
  ${customMedia.between('medium', 'large')`
    margin-top: 38px;

    video {
      width: 768px;
    }
  `}
  ${customMedia.greaterThan('large')`
    margin-top: 38px;

    video {
      width: 900px;
    }
  `}

  video {
    display: block;
    margin: 0 auto;
  }
`

const AboutMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.12em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.8rem;
    letter-spacing: 0.28em;
    margin-top: 60px;
  `}

  color: #0055A4;
  font-weight: bold;
  text-align: center;
`

const AboutSubMessage = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    letter-spacing: 0.02em;;
    margin: 20px 0;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.14em;
    line-height: 30px;
    margin: 30px 0 60px;
    text-align: center;
  `}

  color: #0055A4;
`

const LogoWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 20px;
    width: 120px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 50px;
    width: 200px;
  `}
  
  margin: 0 auto;
`

const LogoText = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 2.6rem;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 3.6rem;
    margin-top: 25px;
  `}

  color: #C92F68;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
`

const LogoSpDescription = styled.div`
  ${customMedia.lessThan('medium')`
    color: #0055A6;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.12em;
    margin-top: 10px;
    text-align: center;
  `}
  ${customMedia.greaterThan('medium')`
    display: none;
  `}
`

const LogoDescription = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1.4rem;
    letter-spacing: 0.02em;
    margin: 10px 0 30px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 1.6rem;
    letter-spacing: 0.14em;
    line-height: 30px;
    margin: 30px 0 50px;
    text-align: center;
  `}

  color: #0055A4;
`

const CheckContainer = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  position: relative;
`

const CheckImageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

const PlaidDescriptionWrapper = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
  `}
`

const PlaidText = styled.div`
  ${customMedia.lessThan('medium')`
    color: #CA2F68;
    font-size: 2.6rem;
    letter-spacing: 0.1em;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan('medium')`
    color: #fff;
    font-size: 3.6rem;
    letter-spacing: 0.1em;
    index: 99;
  `}
  
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  width: 100%;
`

const PlaidSpDescription = styled.div`
  ${customMedia.lessThan('medium')`
    color: #0055A5;
    font-size: 1.6rem;
    font-weight: bold;
    index: 99;
    letter-spacing: 0.12em;
    margin-top: 10px;
    text-align: center;
    width: 100%;
  `}
  ${customMedia.greaterThan('medium')`
    display:none;
  `}
`

const PlaidDescription = styled.div`
  ${customMedia.lessThan('medium')`
    color: #0055A5;
    font-size: 1.4rem;
    margin: 15px 0 20px;
    padding: 0 20px;
  `}
  ${customMedia.greaterThan('medium')`
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 30px;
    letter-spacing: 0.14em;
    line-height: 35px;
    text-align: center;
  `}
  
  width: 100%;
`

const MessageWrapper = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
  
  background-color: #00559F;
  overflow: hidden;
`

const SomeComponent = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}
`

export default IndexPage
